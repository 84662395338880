import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthTokens } from '../_models/auth-tokens';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {
  constructor(private http: HttpClient) { }

  refreshToken(authTokens: AuthTokens): Observable<AuthTokens> {
    return this.http.post<AuthTokens>(`auth/refreshtoken`, {
      token: authTokens.accessToken,
      refreshToken: authTokens.refreshToken
    });
  }
}
