import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { AuthTokens } from '../_models/auth-tokens';
import jwt_decode from 'jwt-decode';

const roleClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
const emailClaim = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  constructor(private localStorageService: LocalStorageService) { }

  getAuthTokens(): AuthTokens {
    const authTokensString = this.localStorageService.getItem('eipauthtokens');
    if (!authTokensString) {
      return null;
    }

    const authTokens = JSON.parse(atob(authTokensString)) as AuthTokens;
    if (authTokens && authTokens.accessToken) {
      return authTokens;
    }

    return null;
  }

  setAuthTokens(authTokens: AuthTokens): void {
    this.localStorageService.setItem('eipauthtokens', btoa(JSON.stringify(authTokens)));
  }

  clearAuthTokens(): void {
    this.localStorageService.removeItem('eipauthtokens');
  }

  email(): string {
    const authTokens = this.getAuthTokens();
    if (!authTokens?.accessToken) {
      return '';
    }

    const decoded = this.getDecodedAccessToken(authTokens.accessToken);
    if (!decoded || !decoded.hasOwnProperty(emailClaim)) {
      return '';
    }

    return decoded[emailClaim];
  }

  isAdmin(): boolean {
    return this.isInRole('Admin');
  }

  isInRole(role: string): boolean {
    const authTokens = this.getAuthTokens();
    if (!authTokens?.accessToken) {
      return false;
    }

    var roles = this.getRole(authTokens.accessToken);
    if (Array.isArray(roles)) {
      return roles.filter(r => r === role).length > 0;
    }

    return roles === role;
  }

  isEipB(): boolean {
    const authTokens = this.getAuthTokens();
    if (!authTokens?.accessToken) {
      return false;
    }

    const decoded = this.getDecodedAccessToken(authTokens.accessToken);
    if (!decoded || !decoded.hasOwnProperty('EIP-B')) {
      return false;
    }

    return String(decoded['EIP-B']).toLowerCase() === 'true';
  }

  isEipA(): boolean {
    const authTokens = this.getAuthTokens();
    if (!authTokens?.accessToken) {
      return false;
    }

    const decoded = this.getDecodedAccessToken(authTokens.accessToken);
    if (!decoded || !decoded.hasOwnProperty('EIP-A')) {
      return false;
    }

    return String(decoded['EIP-A']).toLowerCase() === 'true';
  }

  isReadonly(): boolean {
    const authTokens = this.getAuthTokens();
    if (!authTokens?.accessToken) {
      return false;
    }

    const decoded = this.getDecodedAccessToken(authTokens.accessToken);
    if (!decoded || !decoded.hasOwnProperty('Readonly')) {
      return false;
    }

    return String(decoded['Readonly']).toLowerCase() === 'true';
  }

  private getRole(token: string): string {
    const decoded = this.getDecodedAccessToken(token);
    if (!decoded || !decoded.hasOwnProperty(roleClaim)) {
      return '';
    }

    return decoded[roleClaim];
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}
